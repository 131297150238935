import {Deal} from '../deals/deal';
import {ObtainReviewEvent} from '../obtain-review/obtain-review-event';
import {ReferralEvent} from '../referral/referral-event';

export class Automation {
  id: number;
  partner_id: number;
  active: boolean;
  type: string;
  deal: Deal;
  obtain_review_event: ObtainReviewEvent;
  referral_event: ReferralEvent;

  constructor(o: Partial<Automation>) {
    this.id = o.id;
    this.partner_id = o.partner_id;
    this.active = o.active;
    this.type = o.type;

    if (o.deal) {
      this.deal = new Deal(o.deal);
    }
    if (o.obtain_review_event) {
      this.obtain_review_event = new ObtainReviewEvent(o.obtain_review_event);
    }
    if (o.referral_event) {
      this.referral_event = new ReferralEvent(o.referral_event);
    }
  }

}
