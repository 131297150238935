import * as uuid from 'uuid';
import {AutomationFilterDescriptor} from './automation-filter-descriptor';

export class AutomationFilter {
  local_uuid: string;
  type: string;
  min: number;
  max: number;
  segments: string[];

  public descriptor: AutomationFilterDescriptor;

  constructor(data: Partial<AutomationFilter>) {
    this.type = data.type;
    this.min = data.min;
    this.max = data.max;
    this.segments = data.segments;

    this.descriptor = new AutomationFilterDescriptor(this);

    this.local_uuid = uuid.v4();
  }

  public defaultMinMax(): number[] {
    const min = this.min || (this.type === 'visit_days' ? 1 : 1000 )
    const max = this.max || (this.type === 'visit_days' ? 5 : 2500 )

    return [
      Math.min(min, Math.round(max / 2)),
      Math.max(max, Math.round(min * 2))
    ]
  }

  public valid(): boolean {
    switch (this.type) {
      case 'purchase_volume':
      case 'transaction_amount':
      case 'visit_days':
        if ((this.min != null && this.max == null) || (this.min == null && this.max != null)) {
          return true;
        } else if(this.min == null && this.max == null) {
          return false;
        } else {
          return this.min <= this.max;
        }
      case 'membership_segments':
        return this.segments != null && this.segments.length > 0;
      default:
        console.error('Unknown filter type', this.type)
        return false;
    }
  }

  public asRequest(): Partial<AutomationFilter> | any {
    const request = {
      type: this.type
    };
    switch (this.type) {
      case 'purchase_volume':
      case 'transaction_amount':
      case 'visit_days':
        request['min'] = this.min;
        request['max'] = this.max;
        break;
      case 'membership_segments':
        request['segments'] = this.segments;
        break;
    }
    return request;
  }
}
