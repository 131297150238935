<nav class="navbar navbar-toggleable-md navbar-expand-md navbar-inverse bg-inverse navbar-static-top">
  <a [routerLink]="['./']" class="navbar-brand navbar-nav-left">
    <img alt="" class="navbar-mankido-logo" src="assets/img/logo.svg"/>
    <div class="navbar-merchant-center-text">
      Merchant<br/>
      Center
    </div>
  </a>
  <div class="main-menu d-none d-md-flex justify-content-center">
    <ng-container *ngTemplateOutlet="mainMenu"></ng-container>
  </div>

  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
          (click)="navbarCollapsed = !navbarCollapsed">
    <i class="fa fa-bars text-white fa-lg"></i>
  </button>

  <div [(ngbCollapse)]="navbarCollapsed" class="collapse navbar-collapse" id="navbarSupportedContent">
    <div class="d-block d-md-none">
      <ng-container *ngTemplateOutlet="mainMenu"></ng-container>
    </div>
    <div class="d-none d-md-block mx-auto"></div>

    <ul class="navbar-nav align-self-end justify-content-end text-left pt-1 pt-md-0">
      <li class="nav-item">
        <div class="d-inline-block cursor-pointer" ngbDropdown>
          <span class="nav-link d-flex align-items-center" id="dropdownMenu1" ngbDropdownToggle>
            <img *ngIf="partner?.logo" alt="" class="navbar-partner-logo" src="{{ partner?.logo }}"/>
            <div style="display: inline-block" class="partner-name">
              <div style="margin: 0 .25rem">{{ partner?.name }}</div>
              <div *ngIf="context.partner_id" id="current-business">{{ context.name }}</div>
            </div>
          </span>
          <div aria-labelledby="dropdownMenu1" class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
            <div *ngIf="!merchant?.is_single_context">
              <a (click)="changeContext(c); navbarCollapsed = true" *ngFor="let c of available_contexts"
                 [class.selected]="c.id == context.id && c.partner_id == context.partner_id" class="dropdown-item">
                <span *ngIf="c.partner_id">{{ c.partner?.name }} - {{ c.name }}</span>
                <span *ngIf="!c.partner_id">{{ c.name }}</span>
              </a>
              <div class="dropdown-divider d-none d-md-block"></div>
            </div>
            <div>
              <a (click)="changeContext(w); navbarCollapsed = true" *ngFor="let w of webshops"
                 [class.selected]="w.id == context.id && w.partner_id == context.partner_id" class="dropdown-item d-flex justify-content-between">
                <span>Webshop - {{w.name}}</span>
              </a>
              <a (click)="navbarCollapsed = true" *ngIf="webshops.length === 0" [routerLink]="['./webshop']" class="dropdown-item">
                Webshops
              </a>
              <div class="dropdown-divider d-none d-md-block"></div>
            </div>
            <a (click)="navbarCollapsed = true" *ngIf="!context.partner_id" [routerLink]="['./third-party-access']"
               class="dropdown-item">
              Google Konten
            </a>
            <a (click)="navbarCollapsed = true" [routerLink]="['/referral-settings']" class="dropdown-item">
              Kunden werben Kunden
            </a>
            <a (click)="navbarCollapsed = true" *ngIf="!context.partner_id" [routerLink]="['./client-documents']" class="dropdown-item">Meine Verträge
            </a>
            <a (click)="navbarCollapsed = true" *ngIf="!context.partner_id" [routerLink]="['./invoices']" class="dropdown-item">Rechnungsverlauf</a>
            <a (click)="navbarCollapsed = true" [routerLink]="['./redemption_reports']" class="dropdown-item">Prämienauszüge</a>
            <div class="dropdown-divider d-none d-md-block"></div>
            <a (click)="navbarCollapsed = true" [routerLink]="['./logout']" class="dropdown-item">Abmelden</a>
          </div>
        </div>
      </li>
    </ul>
  </div>
</nav>

<ng-template #mainMenu>
  <ul class="navbar-nav text-left text-md-center mx-md-auto pt-1 pt-md-0">
    <li class="nav-item" routerLinkActive="active">
      <a (click)="navbarCollapsed = true" [routerLink]="['./dashboard']" class="nav-link">Dashboard</a>
    </li>
    <li class="nav-item" routerLinkActive="active">
      <a (click)="navbarCollapsed = true" [routerLink]="['./loyalty']" class="nav-link">Bonussystem</a>
    </li>
    <li *ngIf="!context.is_webshop" class="nav-item" routerLinkActive="active">
      <a (click)="navbarCollapsed = true" [routerLink]="['./campaigns']" class="nav-link">Kampagnen</a>
    </li>
    <li *ngIf="!context.partner_id" class="nav-item" routerLinkActive="active">
      <a (click)="navbarCollapsed = true" [routerLink]="['./automation']" class="nav-link">Automatisierung</a>
    </li>
    <li *ngIf="!context.is_webshop" class="nav-item" routerLinkActive="active">
      <a (click)="navbarCollapsed = true" [routerLink]="['./pos_experiences']" class="nav-link">
        POS Experiences
      </a>
    </li>
    <li *ngIf="context.is_webshop" class="nav-item" routerLinkActive="active">
      <a (click)="navbarCollapsed = true" [routerLink]="['./webshop']" class="nav-link">
        Konfiguration
      </a>
    </li>
    <li class="nav-item" routerLinkActive="active">
      <a (click)="navbarCollapsed = true" [routerLink]="['./support']" class="nav-link">
        Support
      </a>
    </li>
    <li *ngIf="betaService.isBeta()" class="nav-item" routerLinkActive="active">
      <a (click)="navbarCollapsed = true" [routerLink]="['./beta']" class="nav-link">
        ⚠️ Hidden Beta Stuff
      </a>
    </li>
  </ul>
</ng-template>
