export class AutomationRetrospect {
  total: number;
  automation: number;
  transaction_amount: number;
  purchase_volume: number;
  visit_days: number;
  membership_segments: number;

  constructor(v: Partial<AutomationRetrospect>) {
    this.total = v.total;
    this.automation = v.automation;
    this.transaction_amount = v.transaction_amount;
    this.purchase_volume = v.purchase_volume;
    this.visit_days = v.visit_days;
    this.membership_segments = v.membership_segments;
  }
}
