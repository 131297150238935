import {ContentBundle} from '../content-bundle';
import {ContentElementFactory} from '../../campaigns/services/content-element-factory';

export class ContentBundleFactory {
  public static buildForInformationCampaign(): ContentBundle {
    return new ContentBundle({
      content_elements: ContentElementFactory.addPosition([
        ContentElementFactory.buildSpacer(30),
        ContentElementFactory.buildHeading('2'),
        ContentElementFactory.buildSpacer(30),
        ContentElementFactory.buildAsset(),
        ContentElementFactory.buildSpacer(30),
        ContentElementFactory.buildText(),
        ContentElementFactory.buildSpacer(30),
        ContentElementFactory.buildButton(),
        ContentElementFactory.buildSpacer(30)
      ])
    });
  }

  public static buildForInformationWithSpecialPointsEventCampaign(title = null, description = null, specialPointsEvent = null): ContentBundle {
    return new ContentBundle({
      content_elements: ContentElementFactory.addPosition([
        ContentElementFactory.buildSpacer(30),
        ContentElementFactory.buildHeading('2', title),
        ContentElementFactory.buildSpacer(30),
        ContentElementFactory.buildSpecialPointsEvent(specialPointsEvent),
        ContentElementFactory.buildSpacer(30),
        ContentElementFactory.buildText(description),
        ContentElementFactory.buildSpacer(30),
      ])
    });
  }

  public static buildForDealWithSpecialPointsEvent(title = null, description = null, specialPointsEvent = null): ContentBundle {
    return new ContentBundle({
      content_elements: ContentElementFactory.addPosition([
        ContentElementFactory.buildDeal(title, description, specialPointsEvent),
      ])
    });
  }

  public static buildForDealCampaign(): ContentBundle {
    return new ContentBundle({
      content_elements: ContentElementFactory.addPosition([
        ContentElementFactory.buildDeal()
      ])
    });
  }

  public static buildForObtainReviewCampaign(): ContentBundle {
    return new ContentBundle({
      content_elements: ContentElementFactory.addPosition([
        ContentElementFactory.buildSpacer(30),
        ContentElementFactory.buildHeading('1', 'Hat dir dein Besuch bei uns gefallen?'),
        ContentElementFactory.buildSpacer(30),
        ContentElementFactory.buildText(
          'Wir schätzen dich sehr als Kunden und möchten dir immer das bestmögliche Kauferlebnis bieten. Bewerte uns jetzt auf Google und sag uns wie dir dein Einkauf gefallen hat!'
        ),
        ContentElementFactory.buildSpacer(30),
        ContentElementFactory.buildObtainReview(),
        ContentElementFactory.buildSpacer(40)
      ])
    });
  }

  public static buildForReferralCampaign(): ContentBundle {
    return new ContentBundle({
      content_elements: ContentElementFactory.addPosition([
        ContentElementFactory.buildReferral()
      ])
    });
  }

  public static buildForInfoCustomAutomation(): ContentBundle {
    return new ContentBundle({
      content_elements: ContentElementFactory.addPosition([
        ContentElementFactory.buildSpacer(30),
        ContentElementFactory.buildHeading('2'),
        ContentElementFactory.buildSpacer(30),
        ContentElementFactory.buildAsset(),
        ContentElementFactory.buildSpacer(30),
        ContentElementFactory.buildText(),
        ContentElementFactory.buildSpacer(30),
        ContentElementFactory.buildButton(),
        ContentElementFactory.buildSpacer(30)
      ])
    });
  }
}
