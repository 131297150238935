import {AutomationFilter} from './automation-filter';

export class AutomationConfiguration {
  delay: number;
  filters: AutomationFilter[];

  constructor(data: Partial<AutomationConfiguration>) {
    this.delay = data.delay;
    this.filters = data.filters.map(f => new AutomationFilter(f));
  }

  public valid(): boolean {
    if (!this.delay) {
      return false;
    }
    if (!this.filters || this.filters.length === 0) {
      return false;
    }
    return this.filters.every(f => f.valid());
  }

  public asRequest(): Partial<AutomationConfiguration> | any {
    return {
      delay: this.delay,
      filters: this.filters.map(f => f.asRequest())
    }
  }
}
