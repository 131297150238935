<app-navbar *ngIf="merchant?.id"></app-navbar>

<ng-container *ngIf="merchant?.id">
  <div class="banner banner-obtain-review" [routerLink]="['automation', 'obtain_review']" *ngIf="!featureObtainReviewActive && featureReferralActive && featureCustomAutomationActive">
    <img src="assets/img/obtain-review/google-logo.svg">
    Neues Feature: Mehr Google Bewertungen mit Mankido. <span>Mehr erfahren</span>&nbsp;<i class="fa fa-arrow-right"></i>
  </div>
  <div class="banner banner-referral" [routerLink]="['/referral-settings']" *ngIf="!featureReferralActive && featureObtainReviewActive && featureCustomAutomationActive">
    <div class="badge badge-success badge-promo">Neu</div>
    <span>Neues Feature:</span> Deine Kunden empfehlen sich gegenseitig
    <fa-icon [icon]="faArrowRight"></fa-icon>
  </div>
  <div class="banner banner-custom-automation" [routerLink]="['/', 'automation', 'custom_automations']" *ngIf="!featureCustomAutomationActive">
    <div class="badge badge-success" style="margin-right: 0.5em;">Neu</div>
    <span style="font-weight: normal; color: #373A3C;">Erstelle jetzt individuelle Automatisierungen für deine Kunden</span>
    <span style="font-weight: 600; color: #373A3C; margin: 0 0.5em 0 2em;">Jetzt aktivieren</span>
    <img src="assets/icon/arrow-right-regular.svg" style="height: 1em;">
  </div>
</ng-container>

<div class="container">
  <notifications></notifications>
  <router-outlet></router-outlet>
</div>
<modal></modal>
<!-- <progressbar></progressbar> -->
