import {AutomationFilter} from './automation-filter';
import {CustomAutomation} from './custom-automation';

export class AutomationFilterDescriptor {
  constructor(private filter: AutomationFilter) {
  }

  static getIcon(type: string) {
    switch (type) {
      case 'membership_segments':
        return 'filter-membership-segment';
      case 'purchase_volume':
        return 'filter-purchase-volume';
      case 'transaction_amount':
        return 'filter-transaction-amount';
      case 'visit_days':
        return 'filter-visit-days';
      default:
        return '';
    }
  }

  static getTitle(type: string) {
    switch(type) {
      case 'membership_segments':
        return 'Mitgliedschaftssegment';
      case 'purchase_volume':
        return 'Gesamteinkaufswert';
      case 'transaction_amount':
        return 'Einkaufswert';
      case 'visit_days':
        return 'Bisherige Besuche';
      default:
        return '';
    }
  }

  get icon(): string {
    return AutomationFilterDescriptor.getIcon(this.filter.type);
  }

  get title(): string {
    return AutomationFilterDescriptor.getTitle(this.filter.type);
  }
}
